// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { isUSA } from 'shared/get-country'
import QuotesCarousel from 'views/components/carousel/quotes-carousel'
import stephen from './img/stephen.png'
import stephen2x from './img/stephen@2x.png'
import stephen3x from './img/stephen@3x.png'
import holt from './img/holt-logo.png'
import holt2x from './img/holt-logo@2x.png'
import holt3x from './img/holt-logo@3x.png'
import maggie from './img/maggie.png'
import maggie2x from './img/maggie@2x.png'
import maggie3x from './img/maggie@3x.png'
import shawmut from './img/shawmut-logo.png'
import shawmut2x from './img/shawmut-logo@2x.png'
import shawmut3x from './img/shawmut-logo@3x.png'
import stanley from './img/stanley.png'
import stanley2x from './img/stanley@2x.png'
import stanley3x from './img/stanley@3x.png'
import plaza from './img/plaza-logo.png'
import plaza2x from './img/plaza-logo@2x.png'
import plaza3x from './img/plaza-logo@3x.png'
import corey from './img/corey.png'
import corey2x from './img/corey@2x.png'
import corey3x from './img/corey@3x.png'
import bcci from './img/bcci-logo.png'
import bcci2x from './img/bcci-logo@2x.png'
import bcci3x from './img/bcci-logo@3x.png'

const BCProQuotesCarousel = ({
	screenWidth,
	selectedDotColor,
	country,
}: {
	screenWidth: number,
	selectedDotColor: string,
	country: String,
}) => {
	const bidOrTender = isUSA(country) ? 'bid' : 'tender'
	return (
		<Div>
			<QuotesCarousel
				screenWidth={screenWidth}
				selectedDotColor={selectedDotColor}
				quotes={[
					{
						quote: `BuildingConnected provides a streamlined ${bidOrTender} management process that takes the pressure off the estimating team.`,
						name: 'Stephen Fontana',
						title: 'Holt Construction',
						image: {
							src: stephen,
							srcSet: `${stephen2x} 2x, ${stephen3x} 3x`,
						},
						logo: {
							src: holt,
							srcSet: `${holt2x} 2x, ${holt3x} 3x`,
							width: '59px',
							height: '64px',
						},
					},
					{
						quote:
							'With BuildingConnected, you can get the information and documentation to the subcontractors in a matter of minutes.',
						name: 'Maggie Coppa',
						title: 'Shawmut',
						image: {
							src: maggie,
							srcSet: `${maggie2x} 2x, ${maggie3x} 3x`,
						},
						logo: {
							src: shawmut,
							srcSet: `${shawmut2x} 2x, ${shawmut3x} 3x`,
							width: '161px',
							height: '28px',
						},
					},
					{
						quote: `We no longer have to cold call hundreds of subcontractors to find out whether they’re submitting ${bidOrTender}s.`,
						name: 'Stanley Abellard',
						title: 'Plaza Construction',
						image: {
							src: stanley,
							srcSet: `${stanley2x} 2x, ${stanley3x} 3x`,
						},
						logo: {
							src: plaza,
							srcSet: `${plaza2x} 2x, ${plaza3x} 3x`,
							width: '110px',
							height: '64px',
						},
					},
					{
						quote: `BuildingConnected is an extremely easy program to use to manage the entire estimating process from the day you get the drawings to receiving the final ${bidOrTender}s.`,
						name: 'Cory Wilson',
						title: 'BCCI Builders',
						image: {
							src: corey,
							srcSet: `${corey2x} 2x, ${corey3x} 3x`,
						},
						logo: {
							src: bcci,
							srcSet: `${bcci2x} 2x, ${bcci3x} 3x`,
							width: '77px',
							height: '70px',
						},
					},
				]}
			/>
		</Div>
	)
}

export default BCProQuotesCarousel
