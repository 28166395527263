// @flow
import * as React from 'react'
import { Div, Ul, Li } from 'glamorous'
import { countryCodes, isUSA } from 'shared/get-country'
import { Title, H2 } from 'views/components'
import { lightBlue, gray80 } from 'shared/colors'
import { mobile } from 'shared/media-queries'

const USBullets = () => (
	<Div
		display="flex"
		css={{
			[mobile]: { flexDirection: 'column' },
		}}
	>
		<Ul
			color={gray80}
			css={{
				[mobile]: { marginBottom: '0' },
			}}
		>
			<Li>Unlimited bid invitations</Li>
			<Li>Relationship tracking</Li>
			<Li>Unlimited project file storage</Li>
			<Li>Access largest network of contractors</Li>
			<Li>Smart search and instant bid lists</Li>
			<Li>Branded bid invites</Li>
			<Li>Dedicated customer support</Li>
			<Li>View/download document tracking</Li>
		</Ul>
		<Div width="30px" />
		<Ul
			color={gray80}
			css={{
				[mobile]: { marginTop: '0' },
			}}
		>
			<Li>Cloud storage integration</Li>
			<Li>Good faith effort reporting</Li>
			<Li>Bid package leads and notifications</Li>
			<Li>Communication and messaging tools</Li>
			<Li>Procore integrations</Li>
			<Li>NDAs and electronic sealed bids</Li>
			<Li>Public plan rooms</Li>
		</Ul>
	</Div>
)

const OtherBullets = ({ country }) => {
	const ndaWording = () => {
		switch (country) {
			case countryCodes.ie:
			case countryCodes.uk:
				return 'NDAs and electronic sealed tenders'
			default:
				return 'NDAs and lockbox tendering'
		}
	}
	return (
		<Div
			display="flex"
			css={{
				[mobile]: { flexDirection: 'column' },
			}}
		>
			<Ul
				color={gray80}
				css={{
					[mobile]: { marginBottom: '0' },
				}}
			>
				<Li>Unlimited tender invitations</Li>
				<Li>Unlimited project file storage</Li>
				<Li>Smart search and instant tender lists</Li>
				<Li>Branded tender invites</Li>
				<Li>Dedicated customer support</Li>
			</Ul>
			<Div width="30px" />
			<Ul
				color={gray80}
				css={{
					[mobile]: { marginTop: '0' },
				}}
			>
				<Li>View/download document tracking</Li>
				<Li>Tender package leads and notifications</Li>
				<Li>Communication and messaging tools</Li>
				<Li>{ndaWording()}</Li>
			</Ul>
		</Div>
	)
}

const OtherFeatures = ({ country }) => (
	<Div display="flex" alignItems="center" flexDirection="column">
		<Title wording="OTHER FEATURES" color={lightBlue} />
		<Div height="15px" />
		<H2>More tools for your belt.</H2>
		<Div height="15px" />
		<Div
			display="flex"
			css={{
				[mobile]: { flexDirection: 'column' },
			}}
		>
			{isUSA(country) ? <USBullets /> : <OtherBullets country={country} />}
		</Div>
	</Div>
)

export default OtherFeatures
