import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const ManagementImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				filename: file(
					relativePath: {
						eq: "bc-pro/main/bid-management/img/bid-management.png"
					}
				) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage
			fluid={data.filename.childImageSharp.fluid}
			alt="bid management"
		/>
	)
}

export default ManagementImage
