import React from 'react'
import { tablet, mobile } from 'shared/media-queries'
import { countryCodes } from 'shared/get-country'
import styled from 'styled-components'

import bidLevelingImage from './bid-leveling.png'
import bidLevelingImage2x from './bid-leveling@2x.png'
import bidLevelingImage3x from './bid-leveling@3x.png'

import bidLevelingImageUK from './bid-leveling-uk.png'
import bidLevelingImageUK2x from './bid-leveling-uk@2x.png'
import bidLevelingImageUK3x from './bid-leveling-uk@3x.png'

import bidLevelingImageIE from './bid-leveling-ie.png'
import bidLevelingImageIE2x from './bid-leveling-ie@2x.png'
import bidLevelingImageIE3x from './bid-leveling-ie@3x.png'

const Div = styled.img`
	align-self: center;
	${tablet} {
		order: 1;
	}
	${mobile} {
		width: 100%;
	}
`

const USImage = () => (
	<Div
		src={bidLevelingImage}
		srcSet={`
    ${bidLevelingImage2x} 2x,
    ${bidLevelingImage3x} 3x,
  `}
	/>
)

const UKImage = () => (
	<Div
		src={bidLevelingImageUK}
		srcSet={`
    ${bidLevelingImageUK2x} 2x,
    ${bidLevelingImageUK3x} 3x,
  `}
	/>
)

const IEImage = () => (
	<Div
		src={bidLevelingImageIE}
		srcSet={`
    ${bidLevelingImageIE2x} 2x,
    ${bidLevelingImageIE3x} 3x,
  `}
	/>
)

const BidLevelingImage = ({ country }) => {
	switch (country) {
		case countryCodes.us:
			return <USImage />
		case countryCodes.uk:
			return <UKImage />
		case countryCodes.ie:
			return <IEImage />
		default:
			return <USImage />
	}
}

export default BidLevelingImage
