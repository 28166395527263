import React from 'react'
import { Link } from 'gatsby'

import commonButtonClass from './common-button-class'

const ButtonLink = ({
	color,
	bgColor,
	children,
	to,
	href,
	width,
	onClick,
	id,
	padding,
	target,
	className,
}) => {
	return to ? (
		<Link
			id={id}
			className={`${commonButtonClass} ${className}`}
			to={to}
			style={{
				backgroundColor: bgColor,
				color,
				width,
				maxWidth: '100%',
				textAlign: 'center',
				padding,
			}}
			onClick={onClick}
		>
			{children}
		</Link>
	) : (
		<a
			id={id}
			className={`${commonButtonClass} ${className}`}
			href={href}
			style={{
				backgroundColor: bgColor,
				color,
				width,
				maxWidth: '100%',
				textAlign: 'center',
				padding,
			}}
			onClick={onClick}
			target={target}
		>
			{children}
		</a>
	)
}

export default ButtonLink
