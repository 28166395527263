// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { InfoAndImage } from 'views/components'
import Image from './img'

const BidManagement = ({
	screenWidth,
	forwardedRef,
	title,
	subtitle,
	info,
	country,
}: {
	screenWidth: number,
	forwardedRef: React.Ref<'div'>,
	title: string,
	subtitle: string,
	info: string,
	country: string,
}) => (
	<Div ref={forwardedRef}>
		<InfoAndImage
			screenWidth={screenWidth}
			image={<Image country={country} />}
			title={title}
			subtitle={subtitle}
			info={info}
			centered={screenWidth < 768}
			bulletItems={[
				'Send out invites in less than 10 minutes',
				'Manage and track your entire project from one place',
			]}
		/>
	</Div>
)

export default BidManagement
