// @flow
import * as React from 'react'
import { useRef } from 'react'
import { Img } from 'glamorous'
import { isUSA } from 'shared/get-country'
import Layout from 'components/layout'
import {
	Hero,
	SectionSpacer,
	ContentWrapper,
	SectionBreak,
	Footer,
} from 'views/components'
import { cyan } from 'shared/colors'
import heroBackground from './img/hero-background.jpg'
import heroBackground2x from './img/hero-background@2x.jpg'
import { navy, lightBlue } from 'shared/colors'
import Network from './network'
import BidManagement from './bid-management'
import BidLeveling from './bid-leveling'
import QuoteSection from './quote-section'
import AnalyticsAndReporting from './analytics-and-reporting'
import TradeTapp from './tradetapp'
import OtherFeatures from './other-features'
import QuotesCarousel from './quotes-carousel'
import mobileHeroBackground from './img/mobile-hero-background.png'
import mobileHeroBackground2x from './img/mobile-hero-background@2x.png'
import mobileHeroBackground3x from './img/mobile-hero-background@3x.png'
import useScreenWidth from 'hooks/use-screen-width'
import useScrollTo from 'hooks/use-scroll-to'
import withCountry from 'shared/get-country'
import generateContent from './content'

const backgroundImage = (
	<Img src={heroBackground} srcSet={`${heroBackground2x} 2x`} />
)
const mobileBackgroundImage = (
	<Img
		src={mobileHeroBackground}
		srcSet={`
	${mobileHeroBackground2x} 2x,
	${mobileHeroBackground3x} 3x,
	`}
	/>
)

const BCPro = ({ country }) => {
	const analyticsRef = useRef(null)
	const bidManagementRef = useRef(null)

	const content = generateContent(country)

	useScrollTo({
		keysToRefs: {
			analytics: analyticsRef,
			management: bidManagementRef,
		},
	})

	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	if (!country) return null
	const { header, network, management, leveling, footer } = content

	return (
		<Layout
			title="BuildingConnected Pro - Bid Management Software | BuildingConnected"
			description="BuildingConnected Pro helps general contractors and owners easily find and qualify subcontractors, send custom bid invites, and streamline communication."
		>
			<Hero
				backgroundImage={
					screenWidth > 768 ? backgroundImage : mobileBackgroundImage
				}
				backgroundColor={navy}
				heading="BuildingConnected Pro"
				subHeading={header.subheading}
				ctaColor={lightBlue}
				wordingWidth="570px"
			/>
			<SectionSpacer />
			<ContentWrapper>
				<Network screenWidth={screenWidth} {...network} country={country} />
				<SectionBreak />
				<BidManagement
					screenWidth={screenWidth}
					forwardedRef={bidManagementRef}
					{...management}
					country={country}
				/>
				<SectionBreak />
				<BidLeveling
					screenWidth={screenWidth}
					{...leveling}
					country={country}
				/>
				<SectionBreak />
				<QuoteSection />
				<SectionBreak />
				<AnalyticsAndReporting
					screenWidth={screenWidth}
					forwardedRef={analyticsRef}
					country={country}
				/>
				{isUSA(country) && <SectionBreak />}
				{isUSA(country) && <TradeTapp screenWidth={screenWidth} />}
				<SectionBreak />
				<OtherFeatures country={country} />
				<SectionSpacer />
				<QuotesCarousel
					screenWidth={screenWidth}
					selectedDotColor={cyan}
					country={country}
				/>
			</ContentWrapper>
			<SectionSpacer />
			<Footer
				wording={footer.wording}
				wordingMaxWidth="666px"
				ctaColor={lightBlue}
			/>
		</Layout>
	)
}

export default withCountry(BCPro)
