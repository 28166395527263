// @flow
import * as React from 'react'
import { Div, A } from 'glamorous'

import { TitleAndInfoItems } from 'views/components'
import { SectionInfo } from 'views/components'
import Spacer from 'components/spacer'
import Image from './img'
import { laptop, tablet, mobile } from 'shared/media-queries'
import { brandBlue } from 'shared/colors'

const BidLeveling = ({
	screenWidth,
	items,
	title,
	subtitle,
	info,
	country,
}) => (
	<Div>
		<Div maxWidth="960px">
			<SectionInfo
				title={title}
				subtitle={subtitle}
				info={info}
				centered={screenWidth < 768}
			/>
			<Spacer height="50px" />
			<Div
				display="flex"
				css={{
					[tablet]: { flexDirection: 'column' },
				}}
			>
				<Div
					flex="0 0 385px"
					css={{
						[laptop]: { flex: '0 0 300px' },
						[tablet]: { order: '3', flex: '0 0 auto' },
					}}
				>
					<TitleAndInfoItems items={items} />
					<Div height="10px" />
					<A
						href="/bid-leveling"
						fontWeight="600"
						color={brandBlue}
						css={{
							[mobile]: { fontSize: '16px' },
						}}
					>
						Learn More &raquo;
					</A>
				</Div>
				<Div
					width="120px"
					css={{
						flex: '0 0 auto',
						[laptop]: { width: '100px' },
						[tablet]: { order: '2', height: '60px' },
					}}
				/>
				<Image country={country} />
			</Div>
		</Div>
	</Div>
)

export default BidLeveling
