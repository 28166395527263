// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { TitleAndInfoItems } from 'views/components'
import { SectionInfo } from 'views/components'
import Spacer from 'components/spacer'

import { laptop, tablet, mobile } from 'shared/media-queries'
import Image from './img'

const AnalyticsAndReporting = ({
	screenWidth,
	forwardedRef,
	country,
}: {
	screenWidth: number,
	forwardedRef: React.Ref<'div'>,
	country: string,
}) => (
	<Div ref={forwardedRef}>
		<Div maxWidth="960px">
			<SectionInfo
				title="ADVANCED ANALYTICS AND REPORTING"
				subtitle="Make data-driven decisions."
				info="Get unparalleled insights across your company with our detailed performance metrics and historical cost tracking."
				centered={screenWidth < 768}
			/>
		</Div>
		<Spacer height="50px" />
		<Div
			display="flex"
			css={{
				[tablet]: { flexDirection: 'column' },
			}}
		>
			<Div flex="1 1 0%">{<Image country={country} />}</Div>
			<Div
				width="120px"
				css={{
					[tablet]: { height: '50px' },
					[mobile]: { height: '30px' },
				}}
			/>
			<Div
				flex="0 0 385px"
				css={{
					[laptop]: { flex: '0 0 300px' },
					[tablet]: { flex: '0 0 auto' },
				}}
			>
				<TitleAndInfoItems
					items={[
						{
							title: 'Company-wide analytics',
							wording:
								'Make smarter business decisions with our real-time analytics and reports.',
						},
						{
							title: 'Historical cost data',
							wording:
								'Easily keep track of historical costs for your records.',
						},
						{
							title: 'Relationship tracking',
							wording: 'Identify historical relationships with subcontractors.',
						},
					]}
				/>
			</Div>
		</Div>
	</Div>
)

export default AnalyticsAndReporting
