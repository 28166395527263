// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { LargeQuote } from 'views/components'
import image1x from './img/img.png'
import image2x from './img/img@2x.png'
import image3x from './img/img@3x.png'

const QuoteSection = () => (
	<Div>
		<LargeQuote
			image1x={image1x}
			image2x={image2x}
			image3x={image3x}
			quote="The ability to solicit, level, and collaborate on trade partners' proposals will set the bar for the next level of preconstruction processes."
			name="JUSTIN E. BRODNAX, HOAR CONSTRUCTION"
		/>
	</Div>
)

export default QuoteSection
