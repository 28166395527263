// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import { SectionInfo } from 'views/components'
import tradetapp from './img/quals.jpg'
import tradetapp2x from './img/quals@2x.jpg'
import tradetapp3x from './img/quals@3x.jpg'
import ButtonLink from 'components/button-link'
import { lightBlue } from 'shared/colors'
import Spacer from 'components/spacer'
import { laptop, tablet } from 'shared/media-queries'

const TradeTapp = ({ screenWidth }: { screenWidth: number }) => (
	<Div
		display="flex"
		css={{
			[tablet]: { flexDirection: 'column' },
		}}
	>
		<Div
			flex="0 0 380px"
			css={{
				[tablet]: { flex: '0 0 auto' },
			}}
		>
			<SectionInfo
				title="TRADETAPP QUALIFICATIONS"
				subtitle="Find and qualify subs."
				info="Reduce risk and save time with TradeTapp, the most advanced prequalification tool ever. Plus, our open API lets you securely pull subcontractor qualification and contact data into your system."
				centered={screenWidth < 1024}
			/>
			<Spacer height="20px" />
			<Div
				css={{
					[tablet]: { textAlign: 'center' },
				}}
			>
				<ButtonLink
					bgColor={lightBlue}
					style={{ padding: '0 40px' }}
					href="https://www.buildingconnected.com/tradetapp"
					target="_blank"
				>
					Learn more
				</ButtonLink>
			</Div>
			<Spacer height="50px" />
		</Div>
		<Img
			src={tradetapp}
			srcSet={`
				${tradetapp2x} 2x,
				${tradetapp3x} 3x,
			`}
			css={{
				[laptop]: { height: '450px' },
				[tablet]: { height: 'auto', width: '100%' },
			}}
		/>
	</Div>
)

export default TradeTapp
