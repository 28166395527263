import { countryCodes } from 'shared/get-country'

const usCopy = {
	header: {
		subheading:
			'The #1 all-in-one bid management solution for construction professionals',
	},
	footer: {
		wording: 'Try BuildingConnected Pro with your team for free.',
	},
	network: {
		subtitle: 'The largest construction network at your fingertips.',
		info:
			'Access our network of hundreds of thousands of subcontractors and find them based on your specific needs. We keep all their contact information up to date with the latest details to save you time.',
	},
	management: {
		title: 'BID MANAGEMENT',
		subtitle: 'Everything you need to manage your bids.',
		info:
			'Send out invites and see exactly what’s happening with your project all in one place. Easily manage each step, from project drawings to final bid—and everything in between.',
	},
	leveling: {
		title: 'BID LEVELING',
		subtitle: 'The quickest and most accurate way to level your bids.',
		info:
			'Compare bids side by side, plug numbers to easily edit values, and collaborate to get results faster and more efficiently.',
		items: [
			{
				title: 'Find the lowest bidder',
				wording:
					'Plug in values and totals automatically adjust. Toggle other bidders to see who’s making the best offer.',
			},
			{
				title: 'Reduce risk upfront',
				wording:
					'View which subs are qualified. Check their project limit, EMR and more.',
			},
			{
				title: 'Increase transparency',
				wording:
					'Leave notes on any line item to share information and stay organized.',
			},
		],
	},
}
const anzCopy = {
	header: {
		subheading:
			'The first tender management solution with a crowd-sourced, real-time construction network.  ',
	},
	footer: {
		wording: 'Try BuildingConnected Pro with your team',
	},
	network: {
		subtitle: 'Discover the right subcontractors for your projects, faster. ',
		info:
			'Access our real-time construction network and find subcontractors based on your specific needs. We keep all their contact information up to date with the latest details to save you time.',
	},
	management: {
		title: 'TENDER MANAGEMENT',
		subtitle: 'Everything you need to manage your tenders.',
		info:
			'Send out invites and see exactly what’s happening with your project all in one place. Easily manage each step, from project drawings to final tender–and everything in between.',
	},
	leveling: {
		title: 'TENDER COMPARISON',
		subtitle: 'The quickest and most accurate way to compare tenders.',
		info:
			'Compare tenders side by side, add adjustments to easily edit values, and collaborate to get results faster and more efficiently.',
		items: [
			{
				title: 'Find the lowest tender',
				wording:
					'Edit values and see totals automatically adjust. Toggle other tenders to see who’s making the best offer.',
			},
			{
				title: 'Increase transparency',
				wording:
					'Leave notes on any line item to share information and stay organised with the team.',
			},
		],
	},
}

const ukCopy = {
	header: {
		subheading:
			'The first tender management solution with a crowd-sourced, real-time construction network.  ',
	},
	footer: {
		wording: 'Try BuildingConnected Pro with your team',
	},
	network: {
		subtitle: 'Discover the right subcontractors for your projects, faster. ',
		info:
			'Access our real-time construction network and find subcontractors based on your specific needs. We keep all their contact information up to date with the latest details to save you time.',
	},
	management: {
		title: 'TENDER MANAGEMENT',
		subtitle: 'Everything you need to manage your tenders.',
		info:
			'Send out invites and see exactly what’s happening with your project all in one place. Easily manage each step, from project drawings to final tender–and everything in between.',
	},
	leveling: {
		title: 'TENDER COMPARISON',
		subtitle: 'The quickest and most accurate way to compare tenders.',
		info:
			'Compare tenders side by side, add adjustments to easily edit values, and collaborate to get results faster and more efficiently.',
		items: [
			{
				title: 'Find the lowest tender',
				wording:
					'Edit values and see totals automatically adjust. Toggle other tenders to see who’s making the best offer.',
			},
			{
				title: 'Increase transparency',
				wording:
					'Leave notes on any line item to share information and stay organised with the team.',
			},
		],
	},
}

const copy = {
	[countryCodes.us]: usCopy,
	[countryCodes.au]: anzCopy,
	[countryCodes.nz]: anzCopy,
	[countryCodes.uk]: ukCopy,
	[countryCodes.ie]: ukCopy,
}

const content = country => copy[country]

export default content
