// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import { SectionInfo } from 'views/components'
import { lightBlue, navy } from 'shared/colors'
import Spacer from 'components/spacer'
import networkImage from './img/network.png'
import networkImage2x from './img/network@2x.png'
import networkImage3x from './img/network@3x.png'

import otherNetworkImage from './img/other-network.png'
import otherNetworkImage2x from './img/other-network@2x.png'
import otherNetworkImage3x from './img/other-network@3x.png'

import { isUSA } from 'shared/get-country'

import { laptop, tablet, mobile } from 'shared/media-queries'
import { numberOfSubsOnBCWording } from 'shared/constants'

const Image = () => (
	<Img
		src={networkImage}
		srcSet={`
      ${networkImage2x} 2x,
      ${networkImage3x} 3x,
    `}
		css={{
			maxHeight: '500px',
			[laptop]: { height: '270px' },
			[tablet]: { height: 'auto', width: '100%' },
		}}
	/>
)

const OtherImage = () => (
	<Img
		src={otherNetworkImage}
		srcSet={`
      ${otherNetworkImage2x} 2x,
      ${otherNetworkImage3x} 3x,
    `}
		css={{
			maxHeight: '500px',
			[laptop]: { height: '270px' },
			[tablet]: { height: 'auto', width: '100%' },
		}}
	/>
)

const Network = ({ screenWidth, subtitle, info, country }) => (
	<Div
		display="flex"
		css={{
			[tablet]: { display: 'block' },
		}}
	>
		<Div
			flex="0 0 488px"
			css={{
				[laptop]: { flex: '0 0 455px' },
			}}
		>
			<SectionInfo
				title="THE NETWORK"
				subtitle={subtitle}
				subtitleMaxWidth="460px"
				info={info}
				centered={screenWidth < 1024}
			/>
			<Div
				height="15px"
				css={{
					[tablet]: { height: '30px' },
				}}
			/>
			<Div
				display="flex"
				alignItems="center"
				css={{
					[tablet]: { justifyContent: 'center' },
					[mobile]: { flexDirection: 'column', textAlign: 'center' },
				}}
			>
				<Div fontSize="54px" color={lightBlue} fontWeight="700">
					{numberOfSubsOnBCWording}
				</Div>
				<Spacer width="10px" />
				<Div
					fontSize="18px"
					fontWeight="700"
					color={navy}
					lineHeight="1.3"
					maxWidth="240px"
				>
					Subcontractors on the BuildingConnected network
				</Div>
			</Div>
		</Div>
		<Div
			width="120px"
			css={{
				flex: '0 0 auto',
				[laptop]: { width: '100px' },
				[tablet]: { height: '50px' },
			}}
		/>
		<Div display="flex" alignItems="center">
			{!!country && isUSA(country) ? <Image /> : <OtherImage />}
		</Div>
	</Div>
)

export default Network
