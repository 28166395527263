import React from 'react'
import { tablet, mobile } from 'shared/media-queries'
import { countryCodes } from 'shared/get-country'
import styled from 'styled-components'

import analyticsImage from './analytics.png'
import analyticsImage2x from './analytics@2x.png'
import analyticsImage3x from './analytics@3x.png'

import analyticsImageAnz from './analytics-anz.png'
import analyticsImageAnz2x from './analytics-anz@2x.png'
import analyticsImageAnz3x from './analytics-anz@3x.png'

import analyticsImageUk from './analytics-uk.png'
import analyticsImageUk2x from './analytics-uk@2x.png'
import analyticsImageUk3x from './analytics-uk@3x.png'

import analyticsImageIe from './analytics-ie.png'
import analyticsImageIe2x from './analytics-ie@2x.png'
import analyticsImageIe3x from './analytics-ie@3x.png'

const Div = styled.img`
	align-self: center;
	${tablet} {
		order: 1;
	}
	${mobile} {
		width: 100%;
	}
`

const USImage = () => (
	<Div
		src={analyticsImage}
		srcSet={`
    ${analyticsImage2x} 2x,
    ${analyticsImage3x} 3x,
  `}
	/>
)

const ANZImage = () => (
	<Div
		src={analyticsImageAnz}
		srcSet={`
    ${analyticsImageAnz2x} 2x,
    ${analyticsImageAnz3x} 3x,
  `}
	/>
)

const UKImage = () => (
	<Div
		src={analyticsImageUk}
		srcSet={`
    ${analyticsImageUk2x} 2x,
    ${analyticsImageUk3x} 3x,
  `}
	/>
)

const IEImage = () => (
	<Div
		src={analyticsImageIe}
		srcSet={`
    ${analyticsImageIe2x} 2x,
    ${analyticsImageIe3x} 3x,
  `}
	/>
)

const BidLevelingImage = ({ country }) => {
	switch (country) {
		case countryCodes.us:
			return <USImage />
		case countryCodes.nz:
			return <ANZImage />
		case countryCodes.au:
			return <ANZImage />
		case countryCodes.uk:
			return <UKImage />
		case countryCodes.ie:
			return <IEImage />
		default:
			return <USImage />
	}
}

export default BidLevelingImage
