import * as React from 'react'
import { countryCodes } from 'shared/get-country'

import { default as Image } from './image'
import { default as ImageANZ } from './image-anz'
import { default as ImageUK } from './image-uk'
import { default as ImageIE } from './image-ie'

const images = {
	[countryCodes.us]: <Image />,
	[countryCodes.nz]: <ImageANZ />,
	[countryCodes.au]: <ImageANZ />,
	[countryCodes.uk]: <ImageUK />,
	[countryCodes.ie]: <ImageIE />,
}

const constants = ({ country }) => images[country]
export default constants
